import $ from "jquery";

function numberCounter(_targetFrame, _targetNumber, digit) {
  this.count = 0;
  this.diff = 0;
  this.targetCount = parseInt(_targetNumber);
  this.targetCount2 = parseFloat(_targetNumber) % 1;
  this.targetFrame = $("#" + _targetFrame);
  this.timer = null;
  this.counter(digit);
}

numberCounter.prototype.counter = function(digit) {
  var self = this,
    result = null;

  this.diff = this.targetCount - this.count;

  if (this.diff > 0) {
    self.count += Math.ceil(this.diff / 7);
  }

  result = this.count;

  if (this.targetCount2 > 0) {
    result = parseFloat(result + this.targetCount2);
  }

  if (result % 1 > 0) {
    result = result.toFixed(digit || 2);
  }

  this.targetFrame.html(
    "" + result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );

  if (this.count < this.targetCount) {
    this.timer = setTimeout(function() {
      self.counter(digit);
    }, 20);
  } else {
    clearTimeout(this.timer);
  }
};
export default numberCounter;
