import React, { useEffect, useState } from 'react';

import { Box, Grid, Pagination, PaginationItem, Typography } from '@mui/material';
import { useAuth, logout } from '../auth';
import Camera from './Camera';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ViewPhotosPage from './Photos';
import ViewArchivePage from './Archive';
import SettingsPage from './Settings';
import LoginPage from './Login';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/system";
import SegmentedProgressBarWithTip from './SegementedProgressBarWithTip';
import LoadingComponent from './LoadingComponent';
import Fade from '@mui/material/Fade';


const theme = createTheme({
  palette: {
    background: {
      default: "#0d0507",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "dfs",
        },
      },
    },
  },
});

const LoggedinHome = ({ showPhotos, setShowPhotos, setShowCalendar, showVideos, setShowVideos, setAlbumID, state, setState, showSettings, setShowSettings, albumID, loading, setLoading, loadingScreen, setLoadingScreen}) => {
  const [cameras, setCameras] = useState([]);
  const [show, setShow] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  const [cameraId, setCameraId] = useState(2);
  const [authState] = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Number of items per page
  const MAX_MOBILE_WIDTH = 600;
  const [width, setWidth] = useState(window.innerWidth)
  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedCameras = cameras.slice(startIndex, startIndex + itemsPerPage);

  const isMobile = width <= MAX_MOBILE_WIDTH ? '1px' : '21%';

  let token = localStorage.getItem('REACT_TOKEN_AUTH_KEY');


  useEffect(() => {
    setLoading(true);
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    console.log('setting loading screen true')
    if(displayedCameras.length > 0 && !showPhotos)
      setLoading(false)

  }, [displayedCameras])

  useEffect(() => {
    const fetchCamerasAndPhotos = async () => {
      try {
        let token = localStorage.getItem("access_token");
        const refreshToken = localStorage.getItem("refresh_token");
  
        if (!token) return;
  
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
  
        let response = await fetch("/camera/cameras", requestOptions);
  
        if (response.status === 401) {
          if (refreshToken) {
            const refreshResponse = await fetch("/auth/refresh", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ token: refreshToken }),
            });
  
            if (refreshResponse.ok) {
              const refreshData = await refreshResponse.json();
              token = refreshData.access_token;
              localStorage.setItem("access_token", token);
  
              requestOptions.headers["Authorization"] = `Bearer ${token}`;
              response = await fetch("/camera/cameras", requestOptions);
            } else {
              throw new Error("Token refresh failed");
            }
          } else {
            throw new Error("No refresh token available");
          }
        }
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`HTTP error! Status: ${response.status}, Details: ${JSON.stringify(errorData)}`);
        }
  
        const camerasData = await response.json();
  
        // Fetch latest photos for all cameras in parallel
        const photoFetches = camerasData.map((camera) =>
          fetch(`/photos/latest_photo/${camera.album}`)
            .then((res) => (res.ok ? res.json() : null))
            .catch(() => null) // Handle errors gracefully
        );
  
        const photosData = await Promise.all(photoFetches);
  
        // Combine cameras with their respective photos
        const camerasWithPhotos = camerasData.map((camera, index) => ({
          ...camera,
          photoCover: photosData[index], // Attach photo to the camera object
        }));
  
        setCameras(camerasWithPhotos);
        console.log('cameras here')
        console.log(cameras)
      } catch (err) {
        logout()
        console.error("Error fetching cameras and photos:", err);
      }
    };
    fetchCamerasAndPhotos();
  }, []);
  

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };


  const getAllCameras=()=>{
    fetch('/camera/cameras')
    .then(res => res.json())
    .then(data => {
        console.log(cameras)
        setCameras(data)
    })
    .catch(err => console.log(err))
}


const closeModal = () => {
    setShow(false)
}

const showPhotosComponent = (id) => {
    setAlbumID(id)
    setCameraId(id)
    setShowCalendar(true);
    setShowPhotos(true);
}

const updateCamera=(data)=>{
    console.log(data)
    const requestOptions={
        method:'PUT',
        headers:{
            'content-type':'application/json',
            'Authorization':`Bearer ${JSON.parse(token)}`
        },
        body:JSON.stringify(data)
    }

    fetch(`/camera/camera/${cameraId}`,requestOptions)
    .then(res=>res.json())
    .then(data=>{
        console.log(data)

        const reload =window.location.reload()
        reload() 
    })
    .catch(err=>console.log(err))
}

const deleteCamera=(id)=>{

    const requestOptions={
        method:'DELETE',
        headers:{
            'content-type':'application/json',
            'Authorization':`Bearer ${JSON.parse(token)}`
        }
    }
    fetch(`/camera/camera/${id}`,requestOptions)
    .then(res=>res.json())
    .then(data=>{
        console.log(data)
        getAllCameras()
    
    })
    .catch(err=>console.log(err))
}


  return (
    <div className="recipes-container" style={{ marginLeft: isMobile }}>
      <Modal
        BackdropProps={{
          sx: { backgroundColor: "rgb(0,0,0,0.5)" },
        }}
        show={show}
        size="lg"
        onHide={() => setShow(false)}
      >
        {/* Modal Content */}
      </Modal>

      {showVideos && <ViewArchivePage setShowVideos={setShowVideos} />}
      {showSettings && <SettingsPage setShowSettings={setShowSettings} />}
      {loading && <SegmentedProgressBarWithTip/>}
      {loadingScreen && <LoadingComponent/>}
      {showPhotos && <ViewPhotosPage cameraID={cameraId} setShowCalendar={setShowCalendar} setShow={setShowPhotos} loading={loading} setLoading={setLoading} state={state} setState={setState} albumID={albumID} />}

      {(!showPhotos && !showVideos && !showSettings) && (
        <>
        <Fade in={!loading} timeout={1000}>
          <Grid container paddingTop='100px' >
            {displayedCameras.map((camera) => (
              <Grid item xs={6} md={4} key={camera.id} >
                <Camera
                  title={camera.source}
                  name={camera.name}
                  onClick={() => showPhotosComponent(camera.album)}
                  onDelete={() => deleteCamera(camera.id)}
                  status={camera.status}
                  cameraID={camera.album}
                  photoCover={camera.photoCover} // Now included with cameras
                  loading={loading}
                  setLoading={setLoading}
                  setShow={setShow}
                />
              </Grid>
            ))}
          </Grid>
          </Fade>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2, marginBottom: '50px' }}>
            <Pagination
              count={Math.ceil(cameras.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  components={{
                    previous: () => <Typography>PREV</Typography>,
                    next: () => <Typography>NEXT</Typography>,
                  }}
                />
              )}
            />
          </Box>
        </>
      )}
    </div>
  );
}

const LoggedOutHome = () => {
  return (
    <div className="home container">
      <LoginPage />
    </div>
  )
}

const HomePage = ({ showPhotos, setShowPhotos, setShowVideos, setShowCalendar, showVideos, albumID, setAlbumID, state, setState, showSettings, setShowSettings, loading, setLoading, loadingScreen, setLoadingScreen }) => {
  const [logged] = useAuth();
  return (
    <ThemeProvider theme={theme}>
      <div>
        {logged ? <LoggedinHome showPhotos={showPhotos} setShowPhotos={setShowPhotos} setShowVideos={setShowVideos} setShowCalendar={setShowCalendar} showVideos={showVideos} albumID={albumID}
          setAlbumID={setAlbumID} state={state} setState={setState} showSettings={showSettings} setShowSettings={setShowSettings} loading={loading} setLoading={setLoading} loadingScreen={loadingScreen} setLoadingScreen={setLoadingScreen}/> : <LoggedOutHome />}
      </div>
    </ThemeProvider>
  )
}

export default HomePage;
