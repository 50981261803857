import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "../styles/main.css"; // Import CSS styles
import numberCounter from "../numberCount";

const SegmentedProgressBarWithTip = () => {
  const [progress, setProgress] = useState(0); // Tracks progress percentage
  const [step, setStep] = useState(0); // Tracks which loading step we are on
  const loadingAnimBottomRef = [useRef(null), useRef(null), useRef(null)];
  const loadingAnimTopRef = [useRef(null), useRef(null), useRef(null)];

  const loadingAnimBottomText = ["Loading Cameras", "Getting Photos", "Finishing View"];
  const loadingAnimBottomValue = [25, 50, 100];
  const loadingAnimBottomUnit = ["%", "%", "%"];

  const circleRadius = 49; // Circle radius
  const circumference = 2 * Math.PI * circleRadius; // Full stroke length

  useEffect(() => {
    const tl = gsap.timeline();

    loadingAnimBottomRef.forEach((ref, index) => {
      tl.fromTo(
        ref.current,
        { x: 40, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          delay: index === 0 ? 0.6 : "-=0.4",
          onStart: () => {
            new numberCounter(`data${index}`, loadingAnimBottomValue[index]);

            setTimeout(() => {
              setProgress(loadingAnimBottomValue[index]); // Update progress
              setStep(index + 1);
            }, 1000 * index);
          }
        }
      )
      .to(ref.current, { x: -40, opacity: 0, duration: 0.5, delay: 2 });
    });

    return () => tl.kill();
  }, []);

  return (
    <div className="loading-container">
      <div className="loading-circle">
        <svg
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-5 -5 110 110"
          preserveAspectRatio="xMidYMid"
          className="lds-ring"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            r={circleRadius}
            stroke="rgba(255, 255, 255, 0.8)"
            strokeWidth="10"
          />
          <circle
            cx="50"
            cy="50"
            fill="none"
            r={circleRadius}
            stroke="rgba(173, 40, 45, 0.8)"
            strokeWidth="8.5"
            strokeLinecap="round"
            strokeDasharray={circumference}
            strokeDashoffset={circumference * (1 - progress / 100)}
            style={{ transition: "stroke-dashoffset 1s ease-in-out" }}
          />
        </svg>

        {loadingAnimBottomText.map((text, index) => (
          <div key={index} className="loading-text-group">
            <div ref={loadingAnimBottomRef[index]} className="loading-stat-wrapper">
              <div className="loading-stat-data">
                <strong id={`data${index}`}>1</strong>
                <span>{loadingAnimBottomUnit[index]}</span>
              </div>
              <h4>{text}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SegmentedProgressBarWithTip;
