import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  height: '.5rem',
  '@media (min-width:600px)': {
    height: '.5rem',
  }
});

theme.typography.h3 = {
fontSize: '11px',
'@media (min-width:600px)': {
  fontSize: '11px',

},
[theme.breakpoints.up('md')]: {
  fontSize: '1.5rem',
},
};

const Camera=({onClick, name, status, setShow, photoCover, loading, setLoading})=>{

  const [color, setColor] = useState();

  const onClickSettings = () => {
    setShow(true);
  }

    return (
            <Box sx={{ margin: "5px" }}>
            <Card sx={{ borderRadius: "0", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "rgba(0,0,0,0)" }}>
              <CardActionArea>
              <CardMedia
  onClick={onClick}
  component="img"
  src={photoCover?.thumbnail_data 
    ? `data:image/jpeg;base64,${photoCover.thumbnail_data}` 
    : "fallback-image-url.jpg"}
  alt="camera"
  sx={{
    width: "100%", 
    aspectRatio: "2 / 1.3", // Ensures a perfect square at all times
    objectFit: "cover",
    backgroundColor: photoCover?.thumbnail_data ? "transparent" : "#f0f0f0",
    "@media (max-width: 600px)": {
      width: "100%",  // Full width of parent container
      height: "auto", // Will follow aspect ratio
      minWidth: "190px",
      minHeight: "160px", // Maintain square shape
    },
  }}
/>
                <ThemeProvider theme={theme}>
                  <CardActions
                    sx={{
                      backgroundImage: "linear-gradient(to right, rgba(57, 7, 7, 0.7), rgba(182, 20, 26, 0.7));",
                      justifyContent: "space-between",
                      minHeight: "29px",
                    }}
                  >
                    <Typography variant="h3" color="common.white" sx={{ textTransform: "uppercase" }}>
                      {name}
                    </Typography>
                    <CircleIcon sx={{ stroke: "grey", strokeWidth: 2 }} style={{ color: status ? "green" : "black" }} />
                  </CardActions>
                </ThemeProvider>
              </CardActionArea>
            </Card>
          </Box>
    )
}


export default Camera;
